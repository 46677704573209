<template>
	<div class="scoped_cover_div">
		<div class="jobs_table sp">
			<div class>
				<ValidationObserver ref="observer" v-slot="{ invalid }" slim>
					<section>
						<div class="form_section1">
							<table>
								<tr>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Full Name
												<!-- <span>*</span> -->
											</label>
											<input type="text" placeholder="Enter Character Limit 30"
												v-model="service.requester_name" @focus="show_user_name_suggestion = true"
												@blur="hideSuggestions" autocomplete="new-password" :maxlength="max" />
											<div class="dynamic_data_wrapper" v-if="show_user_name_suggestion">
												<span v-for="(user, i) in filteredUser" :key="'delivery_address_' + i"
													@click="populateUserName(user)">
													{{ user.name }}
												</span>
											</div>
										</div>
									</td>
									<!-- <td>
										<div class="label_and_element_wrapper">
											<label>
												Requester Cost Centre
											</label>
											  <input type="text" placeholder=" Enter Requester Cost Centre "  :class="errors[0] ? 'warning-border':''"  v-model="service.cost_center_id" :maxlength="max"/>
											<select v-model="service.cost_center_id">
												<option value="" disabled>Requester Cost Centre </option>
												<option v-for="(costcenter, i) in costcenters" :key="'costcenter' + i"
													:value="costcenter.id">{{ costcenter.name }}</option>
											</select>
										</div>
									</td> -->
									<td>
										<ValidationProvider name="carrier" rules="required" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Select Carrier
													<span>*</span>
												</label>
												<select v-model="service.provider_id" @change="fetchPostalDocument"
													:class="errors[0] ? 'warning-border' : ''">
													<option value="" disabled>Select Carrier</option>
													<option v-for="(carrier, i) in carriers" :key="'carriers' + i"
														:value="carrier.id">{{ carrier.name }}</option>
												</select>
											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="postaldocuments" rules="required" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Select Postal Document Type
													<span>*</span>
												</label>
												<select v-model="service.postal_document_type_id"
													:class="errors[0] ? 'warning-border' : ''"
													@change="changePostaldocument">
													<option value="" disabled> Select Postal Document Type</option>
													<option v-for="(postaldocument, i) in postaldocuments"
														:key="'postaldocuments' + i" :value="postaldocument.id">
														{{ postaldocument.postal_document_type }}</option>
												</select>
											</div>
										</ValidationProvider>
									</td>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Entered Date
												<span>*</span>
											</label>
											<flat-pickr class="date_for_pos_fixed" v-model="service.entered_date"
												:config="configDate" placeholder="Date" name="birthdate"></flat-pickr>
										</div>
									</td>
									<td>
										<ValidationProvider name="weight" rules="required|max:30" v-slot="{ errors }" slim>
											<div class="label_and_element_wrapper">
												<label>
													Weight
													<span>*</span>
												</label>
												<input type="text" placeholder=" Enter Weight"
													:class="errors[0] ? 'warning-border' : ''" v-model="service.weight"
													:maxlength="max" />

											</div>
										</ValidationProvider>
									</td>
								</tr>
								<tr>
									<td>
										<ValidationProvider name="postalcount" rules="required|max:30" v-slot="{ errors }"
											slim>
											<div class="label_and_element_wrapper">
												<label>
													Postal Count
													<span>*</span>
												</label>
												<input type="text" placeholder="Enter Postal Count"
													:class="errors[0] ? 'warning-border' : ''" v-model="service.count"
													:maxlength="max" @change="updateCount($event)" />
											</div>
										</ValidationProvider>
									</td>
									<td>
										<!--   <ValidationProvider name="postalamount" rules="required|max:30" v-slot="{ errors }" slim> -->
										<div class="label_and_element_wrapper">
											<label>
												Amount

											</label>
											<input type="text" placeholder="Enter Amount" v-model="service.postal_amount"
												:maxlength="max" />
										</div>
										<!--  </ValidationProvider> -->
									</td>
									<td>
										<!--  <ValidationProvider name="comment" rules="required|max:30" v-slot="{ errors }" slim> -->
										<div class="label_and_element_wrapper">
											<label>
												Comment

											</label>
											<input type="text" placeholder=" Enter Comment" v-model="service.comment"
												:maxlength="max" />
										</div>
										<!-- </ValidationProvider> -->
									</td>
								</tr>
								<tr>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												Matter Number
											</label>
											<select-dropdown :value="service.matter_number_id" :items="matter_numbers"
												@setSelected="setSelectedMatterNumber">
											</select-dropdown>
										</div>
									</td>
									<td>
										<div class="label_and_element_wrapper">
											<label>
												GL Code
											</label>
											<input type="text" placeholder="GL Code" v-model="service.gl_code"
												:maxlength="max" :disabled="true" />
												
										</div>
									</td>
									<td>
										<a class="new_yellow_bt" @click="changeGlCode = true" style="background: #006A4E;float: unset;margin-top: 9%;">Change GL</a>
									</td>
								</tr>
							</table>
						</div>
					</section>
				</ValidationObserver>

				<section class="centered">
					<a class="link_bt bt_save" @click="submit">Save</a>
				</section>
			</div>
		</div>
        <!-- change gl code -->
        <div class="black-overlay" v-if="changeGlCode">
            <div class="box-modal smallPops" style="background:#004a7c;">
                <div class="body">
                    <ChangeGlcode
                        v-if="changeGlCode"
                        @closeModal="changeGlCode = false"
                        @submit="populateGlCodeApi"
                    />
                </div>
            </div>
        </div>
	</div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import moment from 'moment';
import ChangeGlcode from "./ChangeGlCode.vue";

export default {
	name: "CarrierServices",
	components: {
		flatPickr,
		ChangeGlcode,
	},
	computed: {
		filteredUser() {
			return this.user_list.filter(element => {
				if (element.name.toLowerCase().includes(this.service.requester_name.toLowerCase())) {
					return element;
				}
			})
		}
	},
	data() {
		return {
			max: 30,
			carriers: [],
			services: [],
			postaldocuments: [],
			user_list: [],
			costcenters: [],
            changeGlCode: false,
			show_user_name_suggestion: false,
			configDate: {
				format: "d-m-Y",
				altFormat: "d-m-Y",
				altInput: true
			},
			service: {
				provider_id: "8e00bfb6b2294c95a0808f8327dd82dd",
				postal_document_type_id: "",
				postal_amount: "",
				count: 1,
				comment: "",
				weight: "",
				moment: moment,
				entered_date: moment(new Date()).format('YYYY-MM-DD'),
				requester_name: "",
				cost_center_id: "",
				price: "",
				unit_price: "",
				matter_number_id: '',
				gl_code: '',
			},
			matter_numbers: [{ id: '', label: '' }],
		}
	},
	async created() {
		this.fetchCarriers();
		// this.fetchPostalDocument();
		this.fetchUser();
		this.fetchCostCenter();
		await this.fetchMatterNumber();
		await this.fetchGlCode();
	},
	methods: {
        populateGlCodeApi(glCode) {
            if (glCode !== undefined) {
                this.service.gl_code = glCode;
                this.disable_gl_code = true;
                this.gl_code_placeholder = 'GL Code';
            }
        },
		fetchUser() {
			this.axios.post("api/user/list")
				.then(response => {
					this.user_list = response.data.msg;
				})
				.catch(error => {
					console.log(error);
				});
		},
		hideSuggestions() {
			setTimeout(() => {
				this.show_user_name_suggestion = false;
			}, 200);
		},
		populateUserName(user) {

			this.service.requester_name = user.name;
			this.service.cost_center_id = user.cost.id;

		},
		fetchCarriers() {
			this.axios.get("/api/provider/carrierlist")
				.then(response => {
					this.carriers = response.data.providers;
					this.service.provider_id = "1a08f6b6ba244c90badbac467c4cc3a0";
					// this.fetchServices();
					this.fetchPostalDocument();


				})
				.catch(error => {
					this.toast.error();
				});
		},
		// to get carrier service
		// fetchServices() {
		//   if(this.service.provider_id){
		//   this.axios.get("/api/provider/carrier/"+this.service.provider_id)
		//     .then(response => {
		//       this.services = response.data.provider.servicenames;
		//          // console.log(response.data.provider.servicenames);
		//           // console.log('jo');
		//        if(this.services.length > 0){
		//           this.service.service_providers_id = this.services[0].id;
		//           // this.service.service_provider = this.services[2].name;
		//           // console.log(this.service.service_provider);
		//           // console.log('muthalali');
		//         }
		//       })

		//     .catch(error => {
		//       console.log(error);
		//     });
		//   }
		// },

		// fetchServices() {
		//   if(this.service.provider_id){
		//   this.axios.get("/api/provider/carrier/"+this.service.provider_id)
		//     .then(response => {
		//       this.services = response.data.provider.servicenames;


		//        if(this.services.length > 0){
		//         if(this.service.provider_id == "8e00bfb6b2294c95a0808f8327dd82dd")
		//           this.service.service_providers_id = "ac0fca91948d476cbf14871c2a8a35f5" ; 
		//         else
		//           this.service.service_providers_id = this.services[0].id;
		//         }


		//       })


		//     .catch(error => {
		//       console.log(error);
		//     });

		//     this.fetchPostalDocument(this.service.provider_id);
		//   }
		// },

		// to get postal document type
		fetchPostalDocument() {

			if (this.service.provider_id) {

				this.axios.get("/api/postaldocument/carrier/" + this.service.provider_id)
					.then(response => {
						this.postaldocuments = response.data.postal_document_types;

						if (this.postaldocuments.length > 0) {
							this.service.postal_document_type_id = this.postaldocuments[0].id;
							this.fncalculateamount(this.postaldocuments[0]);
						}


					})
					.catch(error => {
						this.toast.error();
					});
			}
		},
		fncalculateamount(postaldocuments) {

			this.service.postal_amount = (postaldocuments.markup_margin_type == 'per' ? ((((postaldocuments.markup_margin * 1) * parseFloat(postaldocuments.unit_price)) / 100) + parseFloat(postaldocuments.unit_price)) * this.service.count : (parseFloat(postaldocuments.markup_margin) + parseFloat(postaldocuments.unit_price)) * this.service.count).toFixed(2);

		},
		changePostaldocument() {

			this.axios.get("/api/postaldocument/carrier/" + this.service.provider_id)
				.then(response => {
					this.postaldocuments = response.data.postal_document_types;
					let i = 0;
					this.postaldocuments.forEach(item => {


						if (this.service.postal_document_type_id == item.id) {

							this.fncalculateamount(item);
						}
						i++;

					});

				})
				.catch(error => {
					this.toast.error();
				});
		},
		fetchCostCenter() {
			this.axios.get("/api/cost/")
				.then(response => {
					this.costcenters = response.data.cost_centers;
				})
		},
		updateCount(event) {
			this.service.count = event.target.value;

			// this.service.postal_amount =  this.service.weight * this.service.count;
			this.changePostaldocument();

		},
		// updateWeight(event) {

		//  this.service.weight= event.target.value;
		//   this.service.postal_amount =  this.service.weight * this.service.count;

		//  },
		submit() {
			this.$refs.observer.validate();
			this.service.unit_price = this.postaldocuments.unit_price;
			console.log(this.service);

			console.log("bndsbc");

			this.axios.post("/api/transaction", this.service)
				.then(response => {
					this.toast.success(response.data.msg);
					this.$router.go()
					//  this.$emit('redirect');
					//  this.$router.push("/pages/PostalTransactionSummary");
					// console.log("finish")

				})
				.catch(error => {
					console.log(error);
				});
		},
		async fetchMatterNumber() {
			try {
				const matterNumberResponse$ = await this.axios.get('/api/mater-numbers');
				if (matterNumberResponse$) {
					this.matter_numbers = [{ id: '', label: '' }];
					const matterNumbers = matterNumberResponse$.data.matter_number;
					this.matter_numbers.push({
						id: 0,
						label: 'No Matter Number',
					});
					matterNumbers.forEach((matterNumber) => {
						this.matter_numbers.push({
							id: matterNumber.id,
							label: matterNumber.matter_number,
						});
					});
				}
			}
			catch (err) {
				console.log(err);
			}
		},
		async fetchGlCode() {
			try {
				const glCodeResponse$ = await this.axios.get('/api/glcode');
				if (glCodeResponse$) {
					this.service.gl_code = glCodeResponse$.data.gl_code;
				}
			}
			catch (err) {
				console.log(err);
			}
		},
		setSelectedMatterNumber(value) {
			this.service.matter_number_id = '';
			if (typeof value !== 'undefined' && value != null) {
				this.service.matter_number_id = value.id;
			}
		},
	}
};
</script>
<style  scoped >
.scoped_cover_div {
	margin: 0;
}

.scoped_cover_div section {
	padding: 0;
}

.scoped_cover_div .form_section1 {
	margin-top: 0;
}

.jobs_table.sp table {
	width: 100%;
}

.jobs_table.sp table,
.jobs_table.sp table tr td {
	border: 0;
	padding: 0 0 20px 0;
	vertical-align: top;
}

.jobs_table.sp table tr td {
	width: 33%;
}

.user_profile_upload {
	display: block;
	border: 0;
	height: 40px;
	width: 200px;
	background: #063657;
	color: #fff;
	font-size: 18px;
	margin-right: 10px;
	position: relative;
}

.user_profile_upload input {
	opacity: 0;
	width: 100%;
	height: auto;
	position: absolute;
	top: 0;
	left: 0;
}

.user_profile_upload span {
	display: block;
	width: 100%;
	height: 40px;
	text-align: center;
	line-height: 30px;
	text-align: center;
	color: #063657;
	font-weight: normal;
}
</style>